import React from 'react'
import { View, TouchableOpacity, Linking } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

import { MailFilled, BookFilled } from '@ant-design/icons'

export default function Support() {
  return (
    <View style={{ flexDirection: 'row' }}>
      <TouchableOpacity
        onPress={() =>
          Linking.openURL(
            'https://help.autoreserve.com/hc/categories/34039829478041-%E3%82%A2%E3%83%95%E3%82%A3%E3%83%AA%E3%82%A8%E3%82%A4%E3%83%88'
          )
        }
        style={{ flex: 1 }}
      >
        <ShadowBox style={{ flexDirection: 'row', alignItems: 'center' }}>
          <BookFilled style={{ color: Colors.accent, fontSize: 48 }} />
          <View style={{ flex: 1, marginLeft: 24 }}>
            <Text style={{ fontSize: 18, fontWeight: '600' }}>
              ヘルプセンター
            </Text>
          </View>
        </ShadowBox>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => Linking.openURL('mailto:affiliate@autoreserve.com')}
        style={{ flex: 1, marginLeft: 32 }}
      >
        <ShadowBox style={{ flexDirection: 'row', alignItems: 'center' }}>
          <MailFilled style={{ color: Colors.accent, fontSize: 48 }} />
          <View style={{ flex: 1, marginLeft: 24 }}>
            <Text style={{ fontSize: 18, fontWeight: '600' }}>
              メールでのお問い合わせ
            </Text>
            <Text>affiliate@autoreserve.com</Text>
          </View>
        </ShadowBox>
      </TouchableOpacity>
    </View>
  )
}
