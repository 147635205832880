import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import React from 'react'
import {
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
  useWindowDimensions,
} from 'react-native'
import RNModal, { ModalProps, Orientation } from 'react-native-modal'

import { Toast } from 'components/Shared/Toast'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { FontAwesomeIcon } from './FontAwesomeIcon'

interface ModalHeaderProps {
  onClose: () => void
  title: string | React.ReactNode
}

export function ModalHeader({ onClose, title }: ModalHeaderProps) {
  // SafeAreaViewを使うとモーダルのトランジションがおかしくなるのでinsetsを使う必要がある
  const insets = useSafeAreaInsets()
  const { width, sm } = useResponsive()
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 16,
        paddingVertical: width < sm ? 16 : 24,
        borderBottomWidth: 1,
        borderColor: Colors.border,
        paddingTop: Math.max(insets.top + 4, 24),
      }}
    >
      <TouchableOpacity onPress={onClose}>
        <View style={{ width: 30 }}>
          <FontAwesomeIcon icon={faTimes} size={22} color={Colors.primary} />
        </View>
      </TouchableOpacity>
      {typeof title === 'string' ? (
        <Text
          style={{
            fontWeight: '600',
            fontSize: width < sm ? 18 : 22,
            color: Colors.primary,
          }}
        >
          {title}
        </Text>
      ) : (
        title
      )}
      <View style={{ width: 30 }} />
    </View>
  )
}

const supportedOrientations: Orientation[] = [
  'portrait',
  'portrait-upside-down',
  'landscape',
  'landscape-left',
  'landscape-right',
]

export function Modal({
  children,
  style,
  ...props
}: {
  children?: React.ReactNode
  style?: StyleProp<ViewStyle>
  isVisible?: boolean
} & Partial<ModalProps>) {
  const { width: windowWidth, height: windowHeight } = useWindowDimensions()
  // https://github.com/react-native-modal/react-native-modal/blob/2fd79d15af2d79cb794b9a4b385e945339b85afb/src/modal.tsx#L756
  // Toastの表示位置を補正するためModalのstyleに当たっているmarginもしくはデフォルトで当たっているmarginを知る必要がある
  const margin = StyleSheet.flatten(style)?.margin ?? windowWidth * 0.05

  return (
    <RNModal
      supportedOrientations={supportedOrientations}
      deviceHeight={windowHeight}
      deviceWidth={windowWidth}
      style={style}
      {...props}
    >
      {children}
      <View
        pointerEvents="none"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: -margin,
        }}
      >
        <Toast />
      </View>
    </RNModal>
  )
}
