import { merge } from 'lodash'
import React, { useContext, useMemo } from 'react'
import { TextStyle } from 'react-native'
import { ModalProps } from './Modal'

import type { PartialDeep } from 'type-fest'

/**
 * パッケージ内のコンポネントで使用する共通設定（テーマなど）を保持する型
 * Note: 暗黙的な設定が増えるとプロジェクトごとのUIの違いなど確認が大変になるため控え目に使うこと。
 * 他の手段: コンポネントにpropを明示的に渡せるようにし明示的に設定するなど
 */

export interface FullConfig {
  Text: {
    fontSize: number
    fontFamily?: string
    fontWeight?: TextStyle['fontWeight']
  }
  TextInput: {
    fontSize: number
    height: number
  }
  Link: {
    resolveHref: (href: string) => string
  }
  ModalComponent: React.ComponentType<React.PropsWithChildren<ModalProps>>
}

export type Config = PartialDeep<FullConfig>

export type DefaultConfig = typeof defaultConfig

const defaultConfig = {
  Text: {
    fontSize: 14,
  },
  TextInput: {
    fontSize: 14,
    height: 64,
  },
  Link: {
    resolveHref: (href: string) => href,
  },
  ModalComponent: () => <></>,
}

const ConfigContext = React.createContext<Config>({})

function getFullConfig(defaultConfig: DefaultConfig, config: Config) {
  const fullConfig: FullConfig = merge({}, defaultConfig, config)

  return fullConfig
}

export function useConfig(): FullConfig {
  const config = useContext(ConfigContext)

  const fullConfig = useMemo(
    () => getFullConfig(defaultConfig, config),
    [config]
  )

  return fullConfig
}

export function ConfigProvider({
  value,
  children,
}: {
  value: Config
  children?: React.ReactNode
}) {
  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  )
}
