// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/affiliate/medium_user/medium_user_resource.proto" (package "auto_reserve.affiliate.medium_user", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { UserResource } from "../user/user_resource";
/**
 * @generated from protobuf message auto_reserve.affiliate.medium_user.MediumUserResource
 */
export interface MediumUserResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: auto_reserve.affiliate.medium_user.MediumUserResource.Role role = 2;
   */
  role: MediumUserResource_Role;
  /**
   * @generated from protobuf field: auto_reserve.affiliate.user.UserResource user = 3;
   */
  user?: UserResource;
}
/**
 * @generated from protobuf enum auto_reserve.affiliate.medium_user.MediumUserResource.Role
 */
export enum MediumUserResource_Role {
  /**
   * @generated from protobuf enum value: ADMIN = 0;
   */
  ADMIN = 0,
  /**
   * @generated from protobuf enum value: NORMAL = 1;
   */
  NORMAL = 1,
}
// @generated message type with reflection information, may provide speed optimized methods
class MediumUserResource$Type extends MessageType<MediumUserResource> {
  constructor() {
    super("auto_reserve.affiliate.medium_user.MediumUserResource", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: "role",
        kind: "enum",
        T: () => [
          "auto_reserve.affiliate.medium_user.MediumUserResource.Role",
          MediumUserResource_Role,
        ],
      },
      { no: 3, name: "user", kind: "message", T: () => UserResource },
    ]);
  }
  create(value?: PartialMessage<MediumUserResource>): MediumUserResource {
    const message = { id: "", role: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<MediumUserResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: MediumUserResource,
  ): MediumUserResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* auto_reserve.affiliate.medium_user.MediumUserResource.Role role */ 2:
          message.role = reader.int32();
          break;
        case /* auto_reserve.affiliate.user.UserResource user */ 3:
          message.user = UserResource.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.user,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: MediumUserResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* auto_reserve.affiliate.medium_user.MediumUserResource.Role role = 2; */
    if (message.role !== 0) writer.tag(2, WireType.Varint).int32(message.role);
    /* auto_reserve.affiliate.user.UserResource user = 3; */
    if (message.user)
      UserResource.internalBinaryWrite(
        message.user,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.medium_user.MediumUserResource
 */
export const MediumUserResource = new MediumUserResource$Type();
