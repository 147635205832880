import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'
import { Button, Form, Grid, Input } from 'antd'
import { signIn } from 'models/Auth'
import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { View } from 'react-native'
import { useNavigate } from 'react-router-dom'

const { useBreakpoint } = Grid

export default function SignIn() {
  const navigate = useNavigate()
  const screens = useBreakpoint()
  const xs = screens.xs
  const [loading, setLoading] = useState(false)

  const onFinish = async (params: { email: string; password: string }) => {
    setLoading(true)

    const { error } = await signIn(params.email, params.password)
    if (error != null) {
      setLoading(false)
      return
    }

    setLoading(false)

    navigate('/')
  }

  return (
    <View style={{ height: '100%' }}>
      <View
        style={{
          width: xs ? '100%' : 480,
          margin: 'auto',
          padding: 20,
        }}
      >
        <Text style={{ textAlign: 'center', fontWeight: '600', fontSize: 32 }}>
          AR Affiliate
        </Text>
        <Form
          onFinish={onFinish}
          style={{
            width: '100%',
            marginTop: 24,
          }}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="email" placeholder={t('メールアドレス')} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t('パスワードを入力してください'),
              },
            ]}
          >
            <Input type="password" placeholder={t('パスワード')} />
          </Form.Item>
          <Form.Item style={{ marginTop: -16 }}>
            <Text>
              {/* eslint-disable ar-i18n/require-translation-ja */}
              <Trans i18nKey="パスワードをまだ設定してない方は<0>こちら</0>">
                <a
                  href="https://autoreserve.com/account/password/edit"
                  target="_blank"
                  rel="noreferrer"
                >
                  こちら
                </a>
              </Trans>
              {/* eslint-enable ar-i18n/require-translation-ja */}
            </Text>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              {t('ログイン')}
            </Button>
          </Form.Item>
        </Form>
      </View>
    </View>
  )
}
