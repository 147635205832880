import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'
import { message, PageHeader } from 'antd'
import { MediumList } from 'components/Medium/List'
import { signOut } from 'models/Auth'
import { useMedia } from 'models/Medium'
import { getErrorMessage } from 'modules/getErrorMessage'
import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useNavigate } from 'react-router'

export default function Media() {
  const { media, error } = useMedia({})
  const navigate = useNavigate()

  useEffect(() => {
    if (error != null && error.code === 'unauthenticated') {
      message.error(getErrorMessage(error))
      signOut()
      navigate('/sign_in')
    }
  }, [error, navigate])

  return (
    <View>
      <PageHeader ghost={false} title={t('メディア一覧')}>
        <MediumList media={media} />
      </PageHeader>
    </View>
  )
}
