import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'
import { BankForm as BankFormType } from '@hello-ai/ar_shared/src/types/affiliate/Bank'
import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd'
import React from 'react'

export default function BankForm({
  form,
  loading,
  onCreateBank,
}: {
  form: FormInstance<BankFormType>
  loading: boolean
  onCreateBank: () => void
}) {
  return (
    <Form
      form={form}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      initialValues={{}}
      onFinish={onCreateBank}
    >
      <Input.Group>
        <Row>
          <Col span={12}>
            <Form.Item
              label={t('銀行コードを入力してください')}
              name="bank_code"
              labelCol={{ span: 8 }}
              rules={[
                { required: true, message: t('銀行コードを入力してください') },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('支店コード')}
              name="branch_code"
              labelCol={{ span: 8 }}
              rules={[
                { required: true, message: t('支店コードを入力してください') },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
      <Form.Item
        label={t('口座番号')}
        name="account_number"
        rules={[{ required: true, message: t('口座番号を入力してください') }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('口座名義(カナ)')}
        name="account_holder_name"
        rules={[
          { required: true, message: t('口座名義(カナ)を入力してください') },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('タイプ')}
        name="account_holder_type"
        rules={[{ required: true, message: t('タイプを選択してください') }]}
      >
        <Select>
          <Select.Option value="individual">{t('個人')}</Select.Option>
          <Select.Option value="company">{t('法人')}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item wrapperCol={{ span: 24 }}>
        <Button type="primary" htmlType="submit" block disabled={loading}>
          {t('アップデート')}
        </Button>
      </Form.Item>
    </Form>
  )
}
