import React, {
  useState,
  useEffect,
  useContext,
  useImperativeHandle,
} from 'react'
import {
  Animated,
  View,
  SafeAreaView,
  ViewStyle,
  StyleProp,
} from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'

interface ToastMessage {
  id: string
  message: string
  duration?: number
  type: 'info' | 'error'
}

interface ToastMethods {
  displayToastInfo: (message: string, duration?: number) => void
  displayToastError: (message: string, duration?: number) => void
  removeToast: () => void
}

const ToastContext = React.createContext<
  | [
      state: ToastMessage | undefined,
      setState: (value: ToastMessage | undefined) => void
    ]
  | undefined
>(undefined)

export function ToastProvider({ children }: { children?: React.ReactNode }) {
  const [state, setState] = useState<ToastMessage>()

  return (
    <ToastContext.Provider value={[state, setState]}>
      {children}
    </ToastContext.Provider>
  )
}

let toastRef: ToastMethods | null = null
export function setToastRef(ref: ToastMethods | null) {
  toastRef = ref
}

export function displayToastError(message: string, duration?: number) {
  toastRef?.displayToastError(message, duration)
}

export function displayToastInfo(message: string, duration?: number) {
  toastRef?.displayToastInfo(message, duration)
}

export function removeToast() {
  toastRef?.removeToast()
}

export const Toast = React.forwardRef<ToastMethods, {}>((_props, ref) => {
  const { width, sm } = useResponsive()
  const [fadeAnimation] = useState(new Animated.Value(0))

  const context = useContext(ToastContext)
  if (context === undefined) {
    throw new Error('<Toast /> can only be used inside <ToastProvider />')
  }
  const [state, setState] = context

  const { id, message, duration, type } = state ?? {}

  const prevId = usePrevious(id)

  useImperativeHandle(
    ref,
    () => {
      function displayToast(
        type: 'info' | 'error',
        message: string,
        duration?: number
      ) {
        setState({
          id: `${Date.now()}`,
          message,
          duration,
          type,
        })
      }

      function removeToast() {
        setState(undefined)
      }

      return {
        displayToastInfo: (message, duration = 2000) => {
          displayToast('info', message, duration)
        },
        displayToastError: (message, duration = 3000) => {
          displayToast('error', message, duration)
        },
        removeToast: () => {
          removeToast()
        },
      }
    },
    [setState]
  )

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null
    if (prevId !== id) {
      if (id != null) {
        Animated.timing(fadeAnimation, {
          toValue: 1,
          duration: 200,
          useNativeDriver: true,
        }).start(() => {
          timer = setTimeout(() => {
            Animated.timing(fadeAnimation, {
              toValue: 0,
              duration: 200,
              useNativeDriver: true,
            }).start(() => {
              removeToast()
            })
          }, duration)
        })
      }
    }
    return () => {
      if (timer != null) {
        clearTimeout(timer)
      }
    }
  }, [duration, fadeAnimation, id, prevId])

  const messageStyles: StyleProp<ViewStyle> = [
    {
      padding: 16,
      borderRadius: 4,
      shadowRadius: 4,
      elevation: 4,
      shadowColor: '#000',
      shadowOpacity: 0.1,
      shadowOffset: { width: 0, height: 2 },
      width: width < sm ? '100%' : '40%',
    },
    type === 'info' && {
      backgroundColor: 'rgba(171,147,90,0.9)',
    },
    type === 'error' && {
      backgroundColor: Colors.caution90,
    },
    {
      opacity: fadeAnimation,
    },
  ]
  const messageTextStyles = [
    {
      color: 'white',
      lineHeight: 22,
    },
  ]

  if (id == null) {
    return null
  }

  return (
    <View
      pointerEvents="none"
      style={[
        {
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          alignItems: 'flex-end',
        },
        width < sm
          ? {
              left: 16,
              right: 16,
              bottom: 20,
            }
          : {
              right: 20,
              bottom: 20,
            },
      ]}
    >
      <Animated.View style={messageStyles}>
        <Text style={messageTextStyles}>{message}</Text>
      </Animated.View>
      <SafeAreaView />
    </View>
  )
})
