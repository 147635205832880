import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'
import {
  BusinessType,
  DisplayErrors,
  IdentificationForm,
  IdentificationResponse,
  StripeAccount,
  UploadFileListState,
} from '@hello-ai/ar_shared/src/types/affiliate/Identification'
import {
  Button,
  Form,
  FormInstance,
  PageHeader,
  Select,
  Skeleton,
  Spin,
  message,
} from 'antd'
import { UploadFile } from 'antd/lib/upload'
import CompanyFormItems from 'components/Identification/CompanyFormItems'
import IndividualFormItems from 'components/Identification/IndividualFormItems'
import dayjs from 'dayjs'
import { useToken } from 'models/Auth'
import { updateIdentification, useIdentification } from 'models/Identification'
import { getErrorMessage } from 'modules/getErrorMessage'
import { mutate, swrKey } from 'modules/swr'
import React, { useCallback, useEffect, useState } from 'react'
import { View } from 'react-native'
import { useNavigate, useParams } from 'react-router'

export default function Identification() {
  const [loading, setLoading] = useState<boolean>(false)
  const [businessType, setBusinessType] = useState<BusinessType>('individual')
  const [documentFrontIndividualFileList, setDocumentFrontIndividualFileList] =
    useState<UploadFileListState>([])
  const [documentBackIndividualFileList, setDocumentBackIndividualFileList] =
    useState<UploadFileListState>([])
  const [documentFrontCompanyFileList, setDocumentFrontCompanyFileList] =
    useState<UploadFileListState>([])
  const [documentBackCompanyFileList, setDocumentBackCompanyFileList] =
    useState<UploadFileListState>([])
  const [form] = Form.useForm<IdentificationForm>()
  const { mediumId } = useParams<{ mediumId: string }>()

  const handleBusinessType = (businessType: BusinessType) => {
    setBusinessType(businessType)
  }

  const setForm = useCallback((account: StripeAccount, form: FormInstance) => {
    const setErrors = (
      formInstance: FormInstance,
      displayErrors: DisplayErrors
    ) => {
      entries(displayErrors).forEach(([name, errors]) => {
        formInstance.setFields([
          {
            name,
            errors,
          },
        ])
      })
    }

    resetErrors(form)
    entries(account).forEach(([key, value]) => {
      // ファイル系のフォームはUseStateで別管理のため、設定しない
      if (
        key === 'document_front' ||
        key === 'document_back' ||
        key === 'representative_document_front' ||
        key === 'representative_document_back'
      ) {
        return
      }
      if (value === undefined || value === null) return

      if (typeof value === 'boolean') return

      if (
        (key === 'birthday' || key === 'representative_birthday') &&
        typeof value === 'string'
      ) {
        setBirthDay(key, value, form)
      } else if (key === 'display_errors') {
        if (typeof value !== 'string') {
          setErrors(form, value)
        }
      } else if (typeof key === 'string' && typeof value === 'string') {
        form.setFieldValue(key, value)
      }
    })
  }, [])

  const resetErrors = (form: FormInstance) => {
    form
      .getFieldsError()
      .filter((fieldError) => fieldError.errors.length > 0)
      .forEach((field) => {
        field.name.forEach((name) => form.setFields([{ name, errors: [] }]))
      })
  }

  const token = useToken()

  const { data, error } = useIdentification(mediumId!)

  const account = data?.stripe_account

  const navigate = useNavigate()
  useEffect(() => {
    if (error != null) {
      message.error(getErrorMessage(error))
      navigate('/media')
    }

    if (account !== undefined) {
      setBusinessType(account.business_type)
      setForm(account, form)
    }
  }, [form, account, setForm, error, navigate])

  // Object.entriesにforEachするとvalueの型がanyになるので補強
  const entries = <T extends Record<string, any>>(
    object: T
  ): [keyof T, T[keyof T]][] => {
    return Object.entries(object)
  }

  const setBirthDay = (
    key: 'birthday' | 'representative_birthday',
    value: string,
    form: FormInstance
  ) => {
    if (typeof value === 'string') {
      const date = dayjs(value)
      const year = date.format('YYYY')
      const month = date.format('M')
      const day = date.format('D')
      if (
        date !== undefined &&
        year !== undefined &&
        month !== undefined &&
        day !== undefined
      ) {
        form.setFieldValue([key, 'year'], year)
        form.setFieldValue([key, 'month'], month)
        form.setFieldValue([key, 'day'], day)
      }
    }
  }

  //
  // ファイルに関するデータはStateの値を利用
  // business typeについては何故かformに設定するとうまく値が更新されなかったので、こちらもStateに
  // 設定したものをFormDataに詰める
  // それ以外のものは引数に指定したものからappendする
  //
  const makeFormData = (identification: IdentificationForm): FormData => {
    const formData = new FormData()

    // businessTypeはformに設定する値を使うおうとすると上手くいかないので別途state側のもので設定する
    formData.append('business_type', businessType)
    entries(identification).forEach(([key, value]) => {
      if (key === 'business_type') return
      if (value === undefined || value === null) return
      if (key === 'birthday' || key === 'representative_birthday') {
        setBirthdayFormData(key, identification, formData)
        return
      }
      if (typeof value === 'string') {
        formData.append(key, value)
      }
    })

    setUploadFiles(
      {
        document_front: documentFrontIndividualFileList,
        document_back: documentBackIndividualFileList,
        representative_document_front: documentFrontCompanyFileList,
        representative_document_back: documentBackCompanyFileList,
      },
      formData
    )

    return formData
  }

  const setUploadFiles = (
    targetFileObject: {
      document_front: UploadFile<any>[]
      document_back: UploadFile<any>[]
      representative_document_front: UploadFile<any>[]
      representative_document_back: UploadFile<any>[]
    },
    formData: FormData
  ) => {
    Object.entries(targetFileObject).forEach(([fileListName, fileList]) => {
      if (fileList.length > 0) {
        // 未アップロードの場合のみアップロード
        if (
          (fileListName === 'document_front' &&
            data?.stripe_account.document_front == null) ||
          (fileListName === 'document_back' &&
            data?.stripe_account.document_back == null) ||
          (fileListName === 'representative_document_front' &&
            data?.stripe_account.representative_document_front == null) ||
          (fileListName === 'representative_document_back' &&
            data?.stripe_account.representative_document_back == null)
        ) {
          formData.append(
            fileListName,
            fileList[0].originFileObj as Blob,
            fileList[0].fileName
          )
        }
      }
    })
  }

  const setBirthdayFormData = (
    key: 'birthday' | 'representative_birthday',
    identification: IdentificationForm,
    formData: FormData
  ) => {
    let birth
    let year
    let month
    let day
    if (key === 'representative_birthday') {
      birth = identification.representative_birthday
      year = 'representative_year'
      month = 'representative_month'
      day = 'representative_day'
    } else {
      birth = identification.birthday
      year = 'year'
      month = 'month'
      day = 'day'
    }

    if (
      birth !== undefined &&
      birth.day !== undefined &&
      birth.month !== undefined &&
      birth.day !== undefined
    ) {
      formData.append(year, birth.year.toString())
      formData.append(month, birth.month.toString())
      formData.append(day, birth.day.toString())
    }
  }

  const onUpdate = async (identification: IdentificationForm) => {
    setLoading(true)

    const { error } = await updateIdentification(
      mediumId!,
      makeFormData(identification)
    )

    const result = await mutate<IdentificationResponse>(
      swrKey(token, `/media/${mediumId}/stripe_account/edit`)
    )

    if (result?.stripe_account !== undefined) {
      // 郵便番号など一度stripe側に登録すると、変更不可なため、たとえ入力可能だとしても元の郵便番号を返すのでここでフォームの値を設定し直す
      // 入力値があればdisabledにした方が良さそうだが、同様の既存画面(https://restaurants.autoreserve.com/owner/accounts/identification)の挙動に合わせた
      setForm(result.stripe_account, form)

      if (error == null) {
        if (entries(result.stripe_account.display_errors).length === 0) {
          openSuccessNotification()
        } else {
          openErrorNotification(t('未入力の情報があります'))
        }
      }
    }

    if (error != null) {
      openErrorNotification(getErrorMessage(error))
    }

    setLoading(false)
  }

  const openSuccessNotification = () => {
    message.success(t('本人確認情報を更新しました'))
  }

  const openErrorNotification = (messageContent: string) => {
    message.error(messageContent, 20)
  }

  return data === undefined ? (
    <Skeleton active />
  ) : (
    <View>
      <PageHeader ghost={false}>
        <Spin spinning={loading} size="large">
          <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onUpdate}
            initialValues={{}}
          >
            <Form.Item label={t('タイプ')}>
              <Select value={businessType} onChange={handleBusinessType}>
                <Select.Option value="individual">個人</Select.Option>
                <Select.Option value="company">法人</Select.Option>
              </Select>
            </Form.Item>
            {businessType === 'individual' && (
              <IndividualFormItems
                stripeAccount={account}
                documentFrontFileList={documentFrontIndividualFileList}
                documentBackFileList={documentBackIndividualFileList}
                setDocumentFrontFileList={setDocumentFrontIndividualFileList}
                setDocumentBackFileList={setDocumentBackIndividualFileList}
              />
            )}
            {businessType === 'company' && (
              <CompanyFormItems
                stripeAccount={account}
                documentFrontFileList={documentFrontCompanyFileList}
                documentBackFileList={documentBackCompanyFileList}
                setDocumentFrontFileList={setDocumentFrontCompanyFileList}
                setDocumentBackFileList={setDocumentBackCompanyFileList}
              />
            )}

            <Form.Item wrapperCol={{ span: 24 }}>
              <Button type="primary" htmlType="submit" block disabled={loading}>
                アップデート
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </PageHeader>
    </View>
  )
}
