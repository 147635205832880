// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/affiliate/medium_user/medium_user_service.proto" (package "auto_reserve.affiliate.medium_user", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { MediumUser } from "./medium_user_service";
import type { InviteRequest } from "./medium_user_service";
import type { DestroyResponse } from "./medium_user_service";
import type { DestroyRequest } from "./medium_user_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { MediumUserResource } from "./medium_user_resource";
import type { UpdateRequest } from "./medium_user_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.affiliate.medium_user.MediumUser
 */
export interface IMediumUserClient {
  /**
   * @generated from protobuf rpc: Update(auto_reserve.affiliate.medium_user.UpdateRequest) returns (auto_reserve.affiliate.medium_user.MediumUserResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, MediumUserResource>;
  /**
   * @generated from protobuf rpc: Destroy(auto_reserve.affiliate.medium_user.DestroyRequest) returns (auto_reserve.affiliate.medium_user.DestroyResponse);
   */
  destroy(
    input: DestroyRequest,
    options?: RpcOptions,
  ): UnaryCall<DestroyRequest, DestroyResponse>;
  /**
   * @generated from protobuf rpc: Invite(auto_reserve.affiliate.medium_user.InviteRequest) returns (auto_reserve.affiliate.medium_user.MediumUserResource);
   */
  invite(
    input: InviteRequest,
    options?: RpcOptions,
  ): UnaryCall<InviteRequest, MediumUserResource>;
}
/**
 * @generated from protobuf service auto_reserve.affiliate.medium_user.MediumUser
 */
export class MediumUserClient implements IMediumUserClient, ServiceInfo {
  typeName = MediumUser.typeName;
  methods = MediumUser.methods;
  options = MediumUser.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: Update(auto_reserve.affiliate.medium_user.UpdateRequest) returns (auto_reserve.affiliate.medium_user.MediumUserResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, MediumUserResource> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateRequest, MediumUserResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Destroy(auto_reserve.affiliate.medium_user.DestroyRequest) returns (auto_reserve.affiliate.medium_user.DestroyResponse);
   */
  destroy(
    input: DestroyRequest,
    options?: RpcOptions,
  ): UnaryCall<DestroyRequest, DestroyResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DestroyRequest, DestroyResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Invite(auto_reserve.affiliate.medium_user.InviteRequest) returns (auto_reserve.affiliate.medium_user.MediumUserResource);
   */
  invite(
    input: InviteRequest,
    options?: RpcOptions,
  ): UnaryCall<InviteRequest, MediumUserResource> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<InviteRequest, MediumUserResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
