// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/affiliate/auth/auth_service.proto" (package "auto_reserve.affiliate.auth", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Auth } from "./auth_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { SignInResponse } from "./auth_service";
import type { SignInRequest } from "./auth_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.affiliate.auth.Auth
 */
export interface IAuthClient {
  /**
   * @generated from protobuf rpc: SignIn(auto_reserve.affiliate.auth.SignInRequest) returns (auto_reserve.affiliate.auth.SignInResponse);
   */
  signIn(
    input: SignInRequest,
    options?: RpcOptions,
  ): UnaryCall<SignInRequest, SignInResponse>;
}
/**
 * @generated from protobuf service auto_reserve.affiliate.auth.Auth
 */
export class AuthClient implements IAuthClient, ServiceInfo {
  typeName = Auth.typeName;
  methods = Auth.methods;
  options = Auth.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: SignIn(auto_reserve.affiliate.auth.SignInRequest) returns (auto_reserve.affiliate.auth.SignInResponse);
   */
  signIn(
    input: SignInRequest,
    options?: RpcOptions,
  ): UnaryCall<SignInRequest, SignInResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<SignInRequest, SignInResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
