// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/affiliate/medium_user/medium_user_service.proto" (package "auto_reserve.affiliate.medium_user", syntax proto3)
// tslint:disable
import { MediumUserResource } from "./medium_user_resource";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { MediumUserResource_Role } from "./medium_user_resource";
/**
 * @generated from protobuf message auto_reserve.affiliate.medium_user.UpdateRequest
 */
export interface UpdateRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string medium_id = 2;
   */
  mediumId: string;
  /**
   * @generated from protobuf field: auto_reserve.affiliate.medium_user.MediumUserResource.Role role = 3;
   */
  role: MediumUserResource_Role;
}
/**
 * @generated from protobuf message auto_reserve.affiliate.medium_user.DestroyRequest
 */
export interface DestroyRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string medium_id = 2;
   */
  mediumId: string;
}
/**
 * @generated from protobuf message auto_reserve.affiliate.medium_user.DestroyResponse
 */
export interface DestroyResponse {}
/**
 * @generated from protobuf message auto_reserve.affiliate.medium_user.InviteRequest
 */
export interface InviteRequest {
  /**
   * @generated from protobuf field: string username = 1;
   */
  username: string;
  /**
   * @generated from protobuf field: string medium_id = 2;
   */
  mediumId: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest$Type extends MessageType<UpdateRequest> {
  constructor() {
    super("auto_reserve.affiliate.medium_user.UpdateRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "medium_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: "role",
        kind: "enum",
        T: () => [
          "auto_reserve.affiliate.medium_user.MediumUserResource.Role",
          MediumUserResource_Role,
        ],
      },
    ]);
  }
  create(value?: PartialMessage<UpdateRequest>): UpdateRequest {
    const message = { id: "", mediumId: "", role: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest,
  ): UpdateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string medium_id */ 2:
          message.mediumId = reader.string();
          break;
        case /* auto_reserve.affiliate.medium_user.MediumUserResource.Role role */ 3:
          message.role = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string medium_id = 2; */
    if (message.mediumId !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.mediumId);
    /* auto_reserve.affiliate.medium_user.MediumUserResource.Role role = 3; */
    if (message.role !== 0) writer.tag(3, WireType.Varint).int32(message.role);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.medium_user.UpdateRequest
 */
export const UpdateRequest = new UpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestroyRequest$Type extends MessageType<DestroyRequest> {
  constructor() {
    super("auto_reserve.affiliate.medium_user.DestroyRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "medium_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<DestroyRequest>): DestroyRequest {
    const message = { id: "", mediumId: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<DestroyRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DestroyRequest,
  ): DestroyRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string medium_id */ 2:
          message.mediumId = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: DestroyRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string medium_id = 2; */
    if (message.mediumId !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.mediumId);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.medium_user.DestroyRequest
 */
export const DestroyRequest = new DestroyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestroyResponse$Type extends MessageType<DestroyResponse> {
  constructor() {
    super("auto_reserve.affiliate.medium_user.DestroyResponse", []);
  }
  create(value?: PartialMessage<DestroyResponse>): DestroyResponse {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<DestroyResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DestroyResponse,
  ): DestroyResponse {
    return target ?? this.create();
  }
  internalBinaryWrite(
    message: DestroyResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.medium_user.DestroyResponse
 */
export const DestroyResponse = new DestroyResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InviteRequest$Type extends MessageType<InviteRequest> {
  constructor() {
    super("auto_reserve.affiliate.medium_user.InviteRequest", [
      { no: 1, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "medium_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<InviteRequest>): InviteRequest {
    const message = { username: "", mediumId: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<InviteRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: InviteRequest,
  ): InviteRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string username */ 1:
          message.username = reader.string();
          break;
        case /* string medium_id */ 2:
          message.mediumId = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: InviteRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string username = 1; */
    if (message.username !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.username);
    /* string medium_id = 2; */
    if (message.mediumId !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.mediumId);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.medium_user.InviteRequest
 */
export const InviteRequest = new InviteRequest$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.affiliate.medium_user.MediumUser
 */
export const MediumUser = new ServiceType(
  "auto_reserve.affiliate.medium_user.MediumUser",
  [
    { name: "Update", options: {}, I: UpdateRequest, O: MediumUserResource },
    { name: "Destroy", options: {}, I: DestroyRequest, O: DestroyResponse },
    { name: "Invite", options: {}, I: InviteRequest, O: MediumUserResource },
  ],
);
