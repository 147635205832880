import React from 'react'
import { Col, Divider, Form, Input, Row, Select, Typography } from 'antd'
import dayjs from 'dayjs'
import FileUpload from './FileUpload'
import {
  StripeAccount,
  UploadFileListState,
} from '@hello-ai/ar_shared/src/types/affiliate/Identification'
import DateSelect from './DateSelect'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'

export default function CompanyFormItems({
  stripeAccount,
  documentFrontFileList,
  documentBackFileList,
  setDocumentFrontFileList,
  setDocumentBackFileList,
}: {
  stripeAccount: StripeAccount | undefined
  documentFrontFileList: UploadFileListState
  documentBackFileList: UploadFileListState
  setDocumentFrontFileList: React.Dispatch<
    React.SetStateAction<UploadFileListState>
  >
  setDocumentBackFileList: React.Dispatch<
    React.SetStateAction<UploadFileListState>
  >
}) {
  return (
    <>
      <Typography.Title level={3}>{t('法人情報')}</Typography.Title>
      <Form.Item label={t('名前')} name="name">
        <Input />
      </Form.Item>
      <Input.Group>
        <Row>
          <Col span={12}>
            <Form.Item
              label={t('名前(漢字)')}
              name="name_kanji"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('名前(かな)')}
              name="name_kana"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
      {stripeAccount?.tax_id_provided ? (
        <Form.Item label={t('登記番号(会社法人等番号')}>
          <Input disabled value={t('入力済み')} />
        </Form.Item>
      ) : (
        <Form.Item label={t('登記番号(会社法人等番号')} name="tax_id">
          <Input />
        </Form.Item>
      )}
      <Form.Item label={t('電話番号')} name="phone">
        <Input />
      </Form.Item>
      <Form.Item label={t('郵便番号')} name="postal_code">
        <Input />
      </Form.Item>
      {stripeAccount?.postal_code != null && (
        <>
          <Input.Group>
            <Row>
              <Col span={12}>
                <Form.Item
                  label={t('都道府県')}
                  name="state"
                  labelCol={{ span: 8 }}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('市区町村')}
                  name="city"
                  labelCol={{ span: 8 }}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Input.Group>
            <Row>
              <Col span={12}>
                <Form.Item
                  label={t('街・丁目')}
                  name="town_kanji"
                  labelCol={{ span: 8 }}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('街・丁目(かな)')}
                  name="town_kana"
                  labelCol={{ span: 8 }}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Input.Group>
            <Row>
              <Col span={12}>
                <Form.Item
                  label={t('番地')}
                  name="line1_kanji"
                  labelCol={{ span: 8 }}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('番地(かな)')}
                  name="line1_kana"
                  labelCol={{ span: 8 }}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Input.Group>
            <Row>
              <Col span={12}>
                <Form.Item
                  label={t('建物・部屋番号')}
                  name="line2_kanji"
                  labelCol={{ span: 8 }}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('建物・部屋番号(かな)')}
                  name="line2_kana"
                  labelCol={{ span: 8 }}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
        </>
      )}
      <Divider />
      <Typography.Title level={3}>{t('代表者情報')}</Typography.Title>
      <Input.Group>
        <Row>
          <Col span={12}>
            <Form.Item
              label={t('姓(漢字)')}
              name="representative_last_name_kanji"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('名(漢字)')}
              name="representative_first_name_kanji"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
      <Input.Group>
        <Row>
          <Col span={12}>
            <Form.Item
              label={t('姓(かな)')}
              name="representative_last_name_kana"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('名(かな)')}
              name="representative_first_name_kana"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
      <Form.Item
        label={t('生年月日')}
        style={{ marginBottom: 0 }}
        name="representative_birthday"
      >
        <Input.Group>
          <Form.Item
            name={['representative_birthday', 'year']}
            style={{ display: 'inline-block', width: 100 }}
          >
            <DateSelect
              start={1900}
              end={dayjs().year()}
              placeholder={t('年')}
            />
          </Form.Item>
          <Form.Item
            name={['representative_birthday', 'month']}
            style={{ display: 'inline-block', width: 80 }}
          >
            <DateSelect start={1} end={12} placeholder={t('月')} />
          </Form.Item>
          <Form.Item
            name={['representative_birthday', 'day']}
            style={{ display: 'inline-block', width: 80 }}
          >
            <DateSelect start={1} end={31} placeholder={t('日')} />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Form.Item label={t('性別')} name="representative_gender">
        <Select>
          <Select.Option value="male">{t('男性')}</Select.Option>
          <Select.Option value="female">{t('女性')}</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label={t('郵便番号')} name="representative_postal_code">
        <Input />
      </Form.Item>
      {stripeAccount?.representative_postal_code != null && (
        <>
          <Row>
            <Col span={12}>
              <Form.Item
                label={t('都道府県')}
                name="representative_state"
                labelCol={{ span: 8 }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('市区町村')}
                name="representative_city"
                labelCol={{ span: 8 }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label={t('街・丁目')}
                name="representative_town_kanji"
                labelCol={{ span: 8 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('街・丁目(かな)')}
                name="representative_town_kana"
                labelCol={{ span: 8 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label={t('番地')}
                name="representative_line1_kanji"
                labelCol={{ span: 8 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('番地(かな)')}
                name="representative_line1_kana"
                labelCol={{ span: 8 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label={t('建物・部屋番号')}
                name="representative_line2_kanji"
                labelCol={{ span: 8 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('建物・部屋番号(かな)')}
                name="representative_line2_kana"
                labelCol={{ span: 8 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label={t('身分証明書(表)')}
                name="representative_document_front"
                labelCol={{ span: 8 }}
              >
                <FileUpload
                  stripeDocumentId={
                    stripeAccount?.representative_document_front
                  }
                  fileList={documentFrontFileList}
                  setFileList={setDocumentFrontFileList}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('身分証明書(裏)')}
                name="representative_document_back"
                labelCol={{ span: 8 }}
              >
                <FileUpload
                  stripeDocumentId={stripeAccount?.representative_document_back}
                  fileList={documentBackFileList}
                  setFileList={setDocumentBackFileList}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}
