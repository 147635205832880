import React from 'react'
import { View } from 'react-native'
import { mapValues } from 'lodash'

import { withErrorBoundary } from 'components/Shared/ErrorBoundary'

import SignIn from 'pages/SignIn'
import Dashboard from 'navigation/Dashboard'
import NotFound from 'pages/Error/NotFound'

function withFullScreenLayout(Component: React.ComponentType) {
  return function FullScreenLayout() {
    return (
      <View
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
        }}
      >
        <Component />
      </View>
    )
  }
}

export const Pages = mapValues(
  {
    SignIn,
    Dashboard,
    NotFound,
  },
  (Page) => withErrorBoundary(withFullScreenLayout(Page))
)
