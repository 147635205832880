// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/affiliate/medium/medium_service.proto" (package "auto_reserve.affiliate.medium", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Medium } from "./medium_service";
import type { AffiliatesResponse } from "./medium_service";
import type { AffiliatesRequest } from "./medium_service";
import type { TransfersResponse } from "./medium_service";
import type { TransfersRequest } from "./medium_service";
import type { DataResponse } from "./medium_service";
import type { DataRequest } from "./medium_service";
import type { MediumResource } from "./medium_resource";
import type { GetRequest } from "./medium_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListResponse } from "./medium_service";
import type { ListRequest } from "./medium_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.affiliate.medium.Medium
 */
export interface IMediumClient {
  /**
   * @generated from protobuf rpc: List(auto_reserve.affiliate.medium.ListRequest) returns (auto_reserve.affiliate.medium.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse>;
  /**
   * @generated from protobuf rpc: Get(auto_reserve.affiliate.medium.GetRequest) returns (auto_reserve.affiliate.medium.MediumResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, MediumResource>;
  /**
   * @generated from protobuf rpc: Data(auto_reserve.affiliate.medium.DataRequest) returns (auto_reserve.affiliate.medium.DataResponse);
   */
  data(
    input: DataRequest,
    options?: RpcOptions,
  ): UnaryCall<DataRequest, DataResponse>;
  /**
   * @generated from protobuf rpc: Transfers(auto_reserve.affiliate.medium.TransfersRequest) returns (auto_reserve.affiliate.medium.TransfersResponse);
   */
  transfers(
    input: TransfersRequest,
    options?: RpcOptions,
  ): UnaryCall<TransfersRequest, TransfersResponse>;
  /**
   * @generated from protobuf rpc: Affiliates(auto_reserve.affiliate.medium.AffiliatesRequest) returns (auto_reserve.affiliate.medium.AffiliatesResponse);
   */
  affiliates(
    input: AffiliatesRequest,
    options?: RpcOptions,
  ): UnaryCall<AffiliatesRequest, AffiliatesResponse>;
}
/**
 * @generated from protobuf service auto_reserve.affiliate.medium.Medium
 */
export class MediumClient implements IMediumClient, ServiceInfo {
  typeName = Medium.typeName;
  methods = Medium.methods;
  options = Medium.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: List(auto_reserve.affiliate.medium.ListRequest) returns (auto_reserve.affiliate.medium.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListRequest, ListResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Get(auto_reserve.affiliate.medium.GetRequest) returns (auto_reserve.affiliate.medium.MediumResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, MediumResource> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRequest, MediumResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Data(auto_reserve.affiliate.medium.DataRequest) returns (auto_reserve.affiliate.medium.DataResponse);
   */
  data(
    input: DataRequest,
    options?: RpcOptions,
  ): UnaryCall<DataRequest, DataResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DataRequest, DataResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Transfers(auto_reserve.affiliate.medium.TransfersRequest) returns (auto_reserve.affiliate.medium.TransfersResponse);
   */
  transfers(
    input: TransfersRequest,
    options?: RpcOptions,
  ): UnaryCall<TransfersRequest, TransfersResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<TransfersRequest, TransfersResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Affiliates(auto_reserve.affiliate.medium.AffiliatesRequest) returns (auto_reserve.affiliate.medium.AffiliatesResponse);
   */
  affiliates(
    input: AffiliatesRequest,
    options?: RpcOptions,
  ): UnaryCall<AffiliatesRequest, AffiliatesResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<AffiliatesRequest, AffiliatesResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
