import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'
import { isEqual } from 'lodash'
import { useEffect, useRef } from 'react'
import { NavigateProps } from 'react-router'
import { useNavigate } from '../../modules/router'

function resolveHref(to: NavigateProps['to']) {
  if (typeof to === 'string') {
    return to
  }

  return `${to.pathname}${to.search ?? ''}${to.hash ?? ''}`
}

function isNavigationEqual(
  prevProps: NavigateProps | undefined,
  props: NavigateProps
) {
  if (prevProps == null) return false

  return isEqual(
    {
      href: resolveHref(prevProps.to),
      replace: prevProps.replace ?? false,
      state: prevProps.state ?? {},
    },
    {
      href: resolveHref(props.to),
      replace: props.replace ?? false,
      state: props.state ?? {},
    }
  )
}

export function Navigate({ to, replace, state }: NavigateProps) {
  const navigate = useNavigate()

  const navigateRef = useRef(navigate)

  useEffect(() => {
    navigateRef.current = navigate
  }, [navigate])

  const prevProps = usePrevious({ to, replace, state })

  useEffect(() => {
    const shouldBailout = isNavigationEqual(prevProps, { to, replace, state })

    if (shouldBailout) return

    navigateRef.current(to, { replace, state })
  }, [prevProps, to, replace, state])

  return null
}
