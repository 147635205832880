import React, { useMemo } from 'react'
import { To, useLocation } from 'react-router'
import { Navigate } from './Navigate'

export function Redirect({
  to,
  statusCode = 301,
}: {
  to: To
  statusCode?: number
}) {
  const location = useLocation()

  const state = useMemo(() => {
    return {
      redirectedFrom: location.pathname + location.search,
      statusCode,
      ...(location.state as any),
    }
  }, [location.pathname, location.search, location.state, statusCode])

  return <Navigate to={to} state={state} replace />
}
