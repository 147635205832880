import { supportedLocales } from '@hello-ai/ar_shared/src/modules/locale'

export function getLocaleFromPathname(pathname: string) {
  return supportedLocales.find((locale) => {
    return (
      pathname.toLowerCase() === `/${locale.toLowerCase()}` ||
      pathname.toLowerCase().startsWith(`/${locale.toLowerCase()}/`)
    )
  })
}

// /restaurants/:id を /ja/restaurants/:idなど、現在の言語のpathに変換する関数
export function getPathnameWithLocale(pathname: string) {
  const locale = getLocale()

  if (/^[a-zA-Z]+:/.test(pathname)) {
    return pathname
  }

  if (getLocaleFromPathname(pathname) !== undefined) {
    return pathname
  }

  return `/${locale.toLowerCase()}${pathname}`
}

export function getLocale() {
  const url = new URL(window.location.href)

  const localeFromPath = getLocaleFromPathname(url.pathname)

  if (localeFromPath !== undefined) {
    return localeFromPath
  }

  return 'ja'
}
