import { Select } from 'antd'
import React from 'react'

function* range(start: number, end: number) {
  for (let i = start; i <= end; i++) {
    yield i
  }
}

interface DateSelectProps {
  start: number
  end: number
  value?: number
  placeholder: string
  onChange?: (value: number) => void
}

// cf. https://4x.ant.design/components/form/#components-form-demo-customized-form-controls
export default function DateSelect({
  start,
  end,
  value,
  placeholder,
  onChange,
}: DateSelectProps) {
  return (
    <Select value={value} onChange={onChange} placeholder={placeholder}>
      {[...range(start, end)].map((v) => (
        <Select.Option key={v} value={v}>
          {v}
        </Select.Option>
      ))}
    </Select>
  )
}
