const isStaging =
  window.location.hostname.includes('staging') ||
  window.location.hostname.endsWith('.vercel.app')

function getDefaultApiUrl() {
  if (
    ['localhost', 'lvh.me', '10.0.2.2'].some((str) =>
      location.hostname.endsWith(str)
    )
  ) {
    return 'http://api-affiliate.lvh.me:5100'
  }

  if (isStaging) {
    return 'https://api-affiliate.staging.autoreserve.com'
  }

  return 'https://api-affiliate.autoreserve.com'
}

export interface ConfigObject {
  apiUrl?: string
}

class Config {
  static defaultConfig: Required<ConfigObject> = {
    apiUrl: getDefaultApiUrl(),
  }

  config: ConfigObject = {}

  static STORAGE_KEY = 'ForAffiliateWeb:config'

  load() {
    let str
    try {
      str = localStorage.getItem(Config.STORAGE_KEY)
    } catch (e) {
      console.error(e)
    }

    if (str == null) return
    try {
      this.config = JSON.parse(str)
    } catch (e) {
      console.error(e)
    }
  }

  setItem(key: keyof ConfigObject, value: string) {
    this.config[key] = value
    this.save()
  }

  save() {
    const value = JSON.stringify(this.config)
    localStorage.setItem(Config.STORAGE_KEY, value)
  }

  get apiUrl() {
    return this.config.apiUrl ?? this.defaultConfig.apiUrl
  }

  get isExperimental() {
    return process.env.NODE_ENV === 'development' || isStaging
  }

  get isDevOnly() {
    return process.env.NODE_ENV === 'development' || isStaging
  }

  get defaultConfig() {
    return Config.defaultConfig
  }

  get isTest() {
    // @ts-expect-error
    return typeof window.Cypress !== 'undefined'
  }

  get isStaging() {
    return isStaging
  }
}

const config = new Config()
config.load()

export default config
