import { assertNever } from 'modules/assert'
import { createRpcService, rpcOptions } from 'modules/rpc'
import useSWR, { swrKey } from 'modules/swr'
import { useToken } from './Auth'

import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'
import {
  AffiliateAggregationParams,
  AffiliateAggregationResponse,
} from '@hello-ai/ar_shared/src/types/affiliate/AffiliateAggregation'
import {
  AffiliateRewardParams,
  AffiliateRewardResponse,
} from '@hello-ai/ar_shared/src/types/affiliate/AffiliateReward'
import { MediumResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/affiliate/medium/medium_resource'
import {
  AffiliatesRequest,
  DataRequest,
  GetRequest,
  ListRequest,
  TransfersRequest,
} from '@hello-ai/proto/src/gen/auto_reserve/affiliate/medium/medium_service'
import { MediumClient } from '@hello-ai/proto/src/gen/auto_reserve/affiliate/medium/medium_service.client'
import { MediumUserResource_Role } from '@hello-ai/proto/src/gen/auto_reserve/affiliate/medium_user/medium_user_resource'
import { fetcher } from 'modules/swr/swr'

export const mediumService = createRpcService(MediumClient)
export const mediumClient = mediumService.client

export function getRoleName(value: MediumUserResource_Role) {
  switch (value) {
    case MediumUserResource_Role.ADMIN:
      return t('管理者')
    case MediumUserResource_Role.NORMAL:
      return t('一般')
    default: {
      assertNever(value)
    }
  }
}

export function getStatusName(value: MediumResource_Status) {
  switch (value) {
    case MediumResource_Status.UNDER_REVIEW:
      return t('審査中')
    case MediumResource_Status.CERTIFIED:
      return t('承認')
    case MediumResource_Status.NOT_CERTIFIED:
      return t('非承認')
    default: {
      assertNever(value)
    }
  }
}

export function useMedia(params: ListRequest) {
  const token = useToken()

  const { data, mutate, error } = useSWR(
    swrKey(token, `Medium/List`),
    () => mediumClient.list(params, rpcOptions({ token })).response
  )

  return {
    media: data?.media,
    mutate,
    error,
  }
}

export function useMedium(params: GetRequest) {
  const token = useToken()

  const { data, mutate, error } = useSWR(
    swrKey(token, `Medium/Get`, params),
    () => mediumClient.get(params, rpcOptions({ token })).response
  )

  return {
    medium: data,
    mutate,
    error,
  }
}

export function useMediumData(params: DataRequest) {
  const token = useToken()

  const { data, mutate, error } = useSWR(
    swrKey(token, `Medium/Data`, params),
    () => mediumClient.data(params, rpcOptions({ token })).response
  )

  return {
    data: data?.data,
    mutate,
    error,
  }
}

export function useMediumTransfers(params: TransfersRequest) {
  const token = useToken()

  const { data, mutate, error } = useSWR(
    swrKey(token, `Medium/Transfers`, params),
    () => mediumClient.transfers(params, rpcOptions({ token })).response
  )

  return {
    transfers: data?.transfers,
    mutate,
    error,
  }
}

export function useMediumAffiliates(params: AffiliatesRequest) {
  const token = useToken()

  return useSWR(
    swrKey(token, `Medium/Affiliates`, params),
    () => mediumClient.affiliates(params, rpcOptions({ token })).response
  )
}

export function useAffiliateAggregations(
  medium_id: string,
  params: AffiliateAggregationParams
) {
  const token = useToken()
  return useSWR<AffiliateAggregationResponse, any, ReturnType<typeof swrKey>>(
    swrKey(token, `/media/${medium_id}/reports`, params),
    ([_, url]) => fetcher([url, params])
  )
}

export function useAffiliateReward(
  medium_id: string,
  params: AffiliateRewardParams
) {
  const token = useToken()
  return useSWR<AffiliateRewardResponse, any, ReturnType<typeof swrKey>>(
    swrKey(token, `/media/${medium_id}/rewards`, params),
    ([_, url]) => fetcher([url, params])
  )
}
