import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'
import { AffiliateAggregation as Aggregation } from '@hello-ai/ar_shared/src/types/affiliate/AffiliateAggregation'
import {
  MediumResource,
  MediumResource_AffiliateType,
} from '@hello-ai/proto/src/gen/auto_reserve/affiliate/medium/medium_resource'
import { Button, PageHeader, Row, Select, Table } from 'antd'
import DatePicker from 'components/Shared/DatePicker'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { parse } from 'json2csv'
import { useAffiliateAggregations } from 'models/Medium'
import React, { useState } from 'react'
type DateType = 'day' | 'month'
type TypeAndDate = {
  readonly dateType: DateType
  readonly dateTypeLabel: string
  readonly aggregationFormat: 'YYYY/MM' | 'YYYY'
  readonly pickerFormat: 'month' | 'year'
  date: string
}
type FormatType = {
  [Property in DateType]: TypeAndDate
}

const aggregationFormatDay = 'YYYY/MM'
const aggregationFormatMonth = 'YYYY'
const formatAndDates: FormatType = {
  day: {
    dateType: 'day',
    aggregationFormat: aggregationFormatDay,
    pickerFormat: 'month',
    dateTypeLabel: t('日別'),
    date: dayjs().tz().format(aggregationFormatDay),
  },
  month: {
    dateType: 'month',
    aggregationFormat: aggregationFormatMonth,
    pickerFormat: 'year',
    dateTypeLabel: t('月別'),
    date: dayjs().tz().format(aggregationFormatMonth),
  },
}

function PickerWithType({
  formatAndDate,
  onChange,
}: {
  formatAndDate: TypeAndDate
  onChange: ((value: unknown, dateString: string) => void) | undefined
}) {
  return (
    <DatePicker
      picker={formatAndDate.pickerFormat}
      onChange={onChange}
      format={formatAndDate.aggregationFormat}
      value={dayjs(formatAndDate.date, formatAndDate.aggregationFormat).tz()}
      allowClear={false}
    />
  )
}

export function AffiliateAggregation({
  title,
  medium,
}: {
  title: string
  medium: MediumResource
}) {
  const [formatAndDate, setFormatAndDate] = useState(formatAndDates.day)

  const requestDate = () => {
    return formatAndDate.date
  }

  const { data, error } = useAffiliateAggregations(medium.id, {
    date_type: formatAndDate.dateType,
    date: requestDate(),
  })

  const handleDateType = (dateType: DateType) => {
    setFormatAndDate(formatAndDates[dateType])
  }

  const handleDate = (date: string | null) => {
    if (date !== null) {
      setFormatAndDate((prev) => ({ ...prev, date }))
    }
  }

  const download = (fields: { value: string; label: string }[]) => {
    if (data?.reports !== undefined) {
      makeCsv(data.reports, fields)
    }
  }

  const makeCsv = (
    aggregations: Aggregation[],
    fields: { value: string; label: string }[]
  ) => {
    const csv = parse(aggregations, { fields })
    const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' })

    saveAs(blob, `${medium.name}-${requestDate()}.csv`, {
      autoBom: true,
    })
  }
  const columns = [
    {
      title: t('年月'),
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: t('PV'),
      dataIndex: 'pv',
      key: 'pv',
    },
    {
      title: t('UU'),
      dataIndex: 'uu',
      key: 'uu',
    },
    {
      title: t('クリック数'),
      dataIndex: 'click',
      key: 'click',
    },
    {
      title: t('予約リクエスト数'),
      dataIndex: 'number_of_reservation_request',
      key: 'number_of_reservation_request',
    },
    {
      title: t('成果発生数'),
      dataIndex: 'number_of_affiliate_occurred',
      key: 'number_of_affiliate_occurred',
    },
    {
      title: t('発生報酬'),
      dataIndex: 'amount_of_affiliate_occurred',
      key: 'amount_of_affiliate_occurred',
    },
    {
      title: t('確定成果数'),
      dataIndex: 'number_of_affiliate_approved',
      key: 'number_of_affiliate_approved',
    },
    {
      title: t('確定報酬'),
      dataIndex: 'amount_of_affiliate_approved',
      key: 'amount_of_affiliate_approved',
    },
  ]

  const filteredColumns = (affiliateType: MediumResource_AffiliateType) => {
    return affiliateType === MediumResource_AffiliateType.LINK
      ? columns.filter(
          (column) => !['pv', 'uu', 'click'].includes(column.dataIndex)
        )
      : columns
  }

  return (
    <PageHeader ghost={false} title={title} style={{ marginTop: 32 }}>
      <Row style={{ marginTop: 8, marginBottom: 8 }}>
        <Select
          style={{ marginRight: 10 }}
          value={formatAndDate.dateType}
          onChange={handleDateType}
        >
          <Select.Option value="day">{t('日別')}</Select.Option>
          <Select.Option value="month">{t('月別')}</Select.Option>
        </Select>
        <PickerWithType
          formatAndDate={formatAndDate}
          onChange={(_, dateString) => handleDate(dateString)}
        />
      </Row>
      <Row justify="end" style={{ marginTop: 8, marginBottom: 8 }}>
        <Button
          color="primary"
          onClick={() => {
            download([
              { value: 'date', label: t('日付') },
              { value: 'pv', label: t('PV') },
              { value: 'uu', label: t('UU') },
              { value: 'click', label: t('クリック数') },
              {
                value: 'number_of_reservation_request',
                label: t('予約リクエスト数'),
              },
              { value: 'number_of_affiliate_occurred', label: t('発生数') },
              { value: 'amount_of_affiliate_occurred', label: t('発生報酬') },
              { value: 'number_of_affiliate_approved', label: t('確定成果数') },
              { value: 'amount_of_affiliate_approved', label: t('確定報酬') },
            ])
          }}
        >
          {t('ダウンロード')}
        </Button>
      </Row>
      <Table
        rowKey={(record) => record.date}
        columns={filteredColumns(medium.affiliateType)}
        dataSource={data?.reports}
        pagination={false}
      />
    </PageHeader>
  )
}
