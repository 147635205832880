import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker from 'antd/es/date-picker/generatePicker'
import 'antd/es/date-picker/style/index'

// fix dayjs bug
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'

const DatePicker = generatePicker(dayjsGenerateConfig)
dayjs.extend(weekday)
dayjs.extend(localeData)

export default DatePicker
