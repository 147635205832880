import React, { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import { Form, message, PageHeader, Skeleton, Spin, Typography } from 'antd'
import { createBank, useBanks } from 'models/Bank'
import { useNavigate, useParams } from 'react-router'
import { BankForm as BankFormType } from '@hello-ai/ar_shared/src/types/affiliate/Bank'
import { getErrorMessage } from 'modules/getErrorMessage'
import BankForm from 'components/Bank/BankForm'
import { useToken } from 'models/Auth'
import BankList from 'components/Bank/BankList'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'

export default function Bank() {
  const [loading, setLoading] = useState<boolean>(false)
  const [form] = Form.useForm<BankFormType>()
  const { mediumId } = useParams<{ mediumId: string }>()

  const { data, error, mutate } = useBanks(mediumId!)
  const banks = useMemo(() => data?.bank_accounts ?? [], [data?.bank_accounts])

  const navigate = useNavigate()

  useEffect(() => {
    if (error != null) {
      message.error(getErrorMessage(error))
      navigate('/media')
    }
  }, [error, navigate])

  const token = useToken()

  return data === undefined ? (
    <Skeleton active />
  ) : (
    <View>
      <Spin spinning={loading}>
        <BankList bankAccounts={banks} />
        <PageHeader ghost={false}>
          <Typography.Title level={3}>{t('口座登録')}</Typography.Title>
          <BankForm
            form={form}
            loading={loading}
            onCreateBank={async () => {
              setLoading(true)

              const { data: createResponse, error } = await createBank(
                mediumId!,
                form.getFieldsValue()
              )

              if (error == null) {
                form.resetFields()
                mutate()
              }

              setLoading(false)

              if (error != null) {
                message.error(getErrorMessage(error))
              } else if (createResponse != null) {
                message.success(createResponse.message)
              }
            }}
          />
        </PageHeader>
      </Spin>
    </View>
  )
}
