import i18n, { InitOptions, TOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'

import enJson from '@hello-ai/ar_i18n/src/translations/affiliate/en.json'
import jaJson from '@hello-ai/ar_i18n/src/translations/affiliate/ja.json'
import koJson from '@hello-ai/ar_i18n/src/translations/affiliate/ko.json'
import thJson from '@hello-ai/ar_i18n/src/translations/affiliate/th.json'
import zhCnJson from '@hello-ai/ar_i18n/src/translations/affiliate/zh-CN.json'
import zhHkJson from '@hello-ai/ar_i18n/src/translations/affiliate/zh-HK.json'
import zhTwJson from '@hello-ai/ar_i18n/src/translations/affiliate/zh-TW.json'
import { SupportedLocale, initLocale } from '../../../locale'

import i18nextConfig from '@hello-ai/ar_config/i18next/base'

const localeMap: Record<string, Record<string, string>> = {
  ja: jaJson,
  en: enJson,
  'zh-cn': zhCnJson,
  'zh-hk': zhHkJson,
  'zh-tw': zhTwJson,
  ko: koJson,
  th: thJson,
}

function preprocess(data: Record<string, string>) {
  const result: Record<string, string> = {}
  Object.entries(data).forEach(([k, v]) => {
    if (v.length > 0) {
      result[k] = v
    }
  })
  return result
}

function loadLocale(locale: string) {
  const json = localeMap[locale.toLowerCase()]

  i18n.addResourceBundle(locale, 'translation', preprocess(json), true, true)
}

/** call this function as early as possible (in index.js before App's initialization)  */
export function initI18nAffiliate(locale: SupportedLocale) {
  initLocale(locale)
  i18n.use(initReactI18next).init({
    ...(i18nextConfig as Partial<InitOptions>),
    compatibilityJSON: 'v3',
    debug: false,
    lng: locale,
    fallbackLng: {
      zh: ['zh-CN', 'zh-TW', 'zh-HK', 'ja'],
      'zh-TW': ['zh-CN', 'ja'],
    },
    interpolation: {
      escapeValue: false,
    },
  })

  loadLocale(locale)
}

export const t: (key: string, options?: TOptions) => string = i18n.t.bind(i18n)
