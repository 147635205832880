import { useToken } from './Auth'
import useSWR, { mutate, swrKey } from 'modules/swr'
import { fetcher } from 'modules/swr/swr'
import axios, { wrapResponse } from 'modules/axios'
import {
  BankForm,
  BankIndexResponse,
  BankCreateResponse,
} from '@hello-ai/ar_shared/src/types/affiliate/Bank'

export const useBanks = (mediumId: string) => {
  const token = useToken()
  return useSWR<BankIndexResponse, any, ReturnType<typeof swrKey>>(
    swrKey(token, `/media/${mediumId}/stripe_account/banks`),
    ([_, url]) => fetcher([url])
  )
}

export const createBank = async (mediumId: string, params: BankForm) => {
  const { response, error } = await wrapResponse<BankCreateResponse>(
    axios.post(`/media/${mediumId}/stripe_account/banks`, params)
  )

  return {
    data: response?.data,
    error,
  }
}
