import React from 'react'

import { UploadOutlined } from '@ant-design/icons'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'
import { UploadFileListState } from '@hello-ai/ar_shared/src/types/affiliate/Identification'
import { Button, Input, Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload'

const getLastestOneOrEmptyFileList = (
  changeParam: UploadChangeParam<UploadFile<undefined>>
): UploadFile[] => {
  const file = changeParam.file
  if (file.status === 'removed') {
    // 削除ボタン押下時
    return []
  } else {
    return changeParam.fileList.slice(-1)
  }
}

export default function FileUpload({
  stripeDocumentId,
  fileList,
  setFileList,
}: {
  stripeDocumentId: string | undefined
  fileList: UploadFileListState
  setFileList: React.Dispatch<React.SetStateAction<UploadFileListState>>
}) {
  return (
    <>
      {stripeDocumentId === undefined || stripeDocumentId === null ? (
        <Upload
          multiple={false}
          beforeUpload={(_) => false} // デフォルトだとPOSTでアップロードが走るのでfalseにする
          onChange={(changeParam) => {
            setFileList(getLastestOneOrEmptyFileList(changeParam))
          }}
          fileList={fileList}
        >
          <Button icon={<UploadOutlined />}>{t('アップロード')}</Button>
        </Upload>
      ) : (
        <Input disabled value={t('入力済み')} />
      )}
    </>
  )
}
