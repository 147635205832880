import { useToken } from './Auth'
import useSWR, { swrKey } from 'modules/swr'
import {
  IdentificationResponse,
  IdentificationUpdateResponse,
} from '@hello-ai/ar_shared/src/types/affiliate/Identification'
import { fetcher } from 'modules/swr/swr'
import axios, { wrapResponse } from 'modules/axios'

export const useIdentification = (mediumId: string) => {
  const token = useToken()
  return useSWR<IdentificationResponse, any, ReturnType<typeof swrKey>>(
    swrKey(token, `/media/${mediumId}/stripe_account/edit`),
    ([_, url]) => fetcher([url])
  )
}

export const updateIdentification = async (
  mediumId: string,
  formData: FormData
) => {
  const { response, error } = await wrapResponse(
    axios.patch<IdentificationUpdateResponse>(
      `/media/${mediumId}/stripe_account`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    )
  )

  return {
    data: response?.data,
    error,
  }
}
