import React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from 'antd'
import { Route } from 'antd/lib/breadcrumb/Breadcrumb'

const { Text } = Typography

export function itemRender(
  route: Route,
  params: any,
  routes: Array<Route>,
  paths: Array<string>
) {
  const last = routes.indexOf(route) === routes.length - 1
  return last ? (
    <Text>{route.breadcrumbName}</Text>
  ) : (
    <Link to={route.path}>{route.breadcrumbName}</Link>
  )
}
