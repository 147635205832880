import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'
import { AffiliateReward as Reward } from '@hello-ai/ar_shared/src/types/affiliate/AffiliateReward'
import { MediumResource } from '@hello-ai/proto/src/gen/auto_reserve/affiliate/medium/medium_resource'
import { Button, PageHeader, Row, Table, Typography } from 'antd'
import DatePicker from 'components/Shared/DatePicker'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { parse } from 'json2csv'
import { useAffiliateReward } from 'models/Medium'
import React, { useState } from 'react'

const yearFormat = 'YYYY'

export function AffiliateReward({
  title,
  medium,
}: {
  title: string
  medium: MediumResource
}) {
  const [year, setYear] = useState(dayjs().tz().format(yearFormat))

  const { data } = useAffiliateReward(medium.id, { year })

  const download = (fields: { value: string; label: string }[]) => {
    if (data?.rewards !== undefined) {
      makeCsv(data.rewards, fields)
    }
  }

  const makeCsv = (
    rewards: Reward[],
    fields: { value: string; label: string }[]
  ) => {
    const csv = parse(rewards, { fields })
    const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' })

    saveAs(blob, `${medium.name}-reward-${year}.csv`, {
      autoBom: true,
    })
  }

  return (
    <PageHeader ghost={false} title={title} style={{ marginTop: 32 }}>
      <Row style={{ marginTop: 8, marginBottom: 8 }}>
        <Typography.Text style={{ marginTop: 5, marginRight: 20 }}>
          {t('検索年月')}
        </Typography.Text>
        <DatePicker
          picker={'year'}
          onChange={(_, dateString) => {
            setYear(dateString)
          }}
          format={yearFormat}
          defaultValue={dayjs(year, yearFormat).tz()}
          allowClear={false}
        />
      </Row>
      <Row justify="end" style={{ marginTop: 8, marginBottom: 8 }}>
        <Button
          color="primary"
          onClick={() => {
            download([
              { value: 'date', label: t('日付') },
              { value: 'amount_of_affiliate_approved', label: t('成果報酬額') },
              { value: 'amount_of_carried_forward', label: t('先月繰越金額') },
              { value: 'amount_of_transfer', label: t('振込対象額') },
            ])
          }}
        >
          {t('ダウンロード')}
        </Button>
      </Row>
      <Table
        rowKey={(record) => record.date}
        columns={[
          {
            title: t('年月'),
            dataIndex: 'date',
            key: 'date',
          },
          {
            title: t('成果報酬額'),
            dataIndex: 'amount_of_affiliate_approved',
            key: 'amount_of_affiliate_approved',
          },
          {
            title: t('先月繰越金額'),
            dataIndex: 'amount_of_carried_forward',
            key: 'amount_of_carried_forward',
          },
          {
            title: t('振込対象額'),
            dataIndex: 'amount_of_transfer',
            key: 'amount_of_transfer',
          },
        ]}
        dataSource={data?.rewards}
        pagination={false}
      />
    </PageHeader>
  )
}
