import {
  Config as ArSharedConfig,
  ConfigProvider as ArSharedConfigProvider,
} from '@hello-ai/ar_shared/src/components/Config'
import { initAuthConfig } from '@hello-ai/ar_shared/src/modules/auth'
import { supportedLocales } from '@hello-ai/ar_shared/src/modules/locale'
import { ResponsiveProvider } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { ConfigProvider, message } from 'antd'
import locale from 'antd/lib/locale/ja_JP'
import { Modal } from 'components/Shared/Modal'
import { Toast, ToastProvider, setToastRef } from 'components/Shared/Toast'
import { onError, signOut, useToken } from 'models/Auth'
import instance from 'modules/axios'
import { getErrorMessage } from 'modules/getErrorMessage'
import { history } from 'modules/history'
import { Pages } from 'pages'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import {
  Route,
  Router,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import './App.css'
import { Redirect } from './components/Shared/Redirect'
import { getLocale } from './modules/locale'
import { Navigate } from './components/Shared/Navigate'

const config: ArSharedConfig = {
  Text: {
    fontSize: 14,
    fontFamily: '"Hiragino Sans", sans-serif',
  },
  TextInput: {
    fontSize: 14,
    height: 64,
  },
  ModalComponent: Modal,
}

initAuthConfig({
  useToken,
  onError,
})

function RedirectLocale() {
  const location = useLocation()
  return <Redirect to={location} statusCode={302} />
}

function LocaleRoutes({
  children,
  location,
}: {
  children?: React.ReactNode
  location?: string | Partial<Location>
}) {
  return (
    <Routes location={location}>
      {supportedLocales.map((locale) => {
        return (
          <Route key={locale} path={`/${locale.toLowerCase()}`}>
            {children}
          </Route>
        )
      })}
      <Route path="*" element={<RedirectLocale />} />
    </Routes>
  )
}

interface PrivateRouteProps {
  location: Location
  children: React.ReactElement | null
}

function PrivateRoute({ location, children }: PrivateRouteProps) {
  const token = useToken()

  const navigate = useNavigate()

  useEffect(() => {
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          message.error(getErrorMessage(error))
          signOut()
          navigate('/sign_in')
        }
        return Promise.reject(error)
      }
    )
  }, [navigate, token])

  return token != null ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: '/sign_in',
      }}
      state={{ from: location }}
    />
  )
}

function Container() {
  const historyRef = useRef<typeof history>()

  if (historyRef.current == null) {
    historyRef.current = history
  }

  const currentHistory = historyRef.current
  const [state, setState] = useState({
    action: currentHistory.action,
    location: currentHistory.location,
  })
  useLayoutEffect(() => history.listen(setState), [currentHistory])

  return (
    <Router
      location={state.location}
      navigationType={state.action}
      navigator={currentHistory}
    >
      <LocaleRoutes location={state.location}>
        <Route path="sign_in" element={<Pages.SignIn />} />
        <Route
          path=""
          element={
            <PrivateRoute location={location}>
              <Pages.Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute location={location}>
              <Pages.Dashboard />
            </PrivateRoute>
          }
        />
      </LocaleRoutes>
    </Router>
  )
}

export default function App() {
  return (
    <ArSharedConfigProvider value={config}>
      <ResponsiveProvider>
        <ToastProvider>
          <ConfigProvider locale={locale} autoInsertSpaceInButton={false}>
            <Container />
            <Toast
              ref={(ref) => {
                setToastRef(ref)
              }}
            />
          </ConfigProvider>
        </ToastProvider>
      </ResponsiveProvider>
    </ArSharedConfigProvider>
  )
}
