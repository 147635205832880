// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/affiliate/medium/medium_service.proto" (package "auto_reserve.affiliate.medium", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { MediumResource } from "./medium_resource";
/**
 * @generated from protobuf message auto_reserve.affiliate.medium.ListRequest
 */
export interface ListRequest {}
/**
 * @generated from protobuf message auto_reserve.affiliate.medium.ListResponse
 */
export interface ListResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.affiliate.medium.MediumResource media = 1;
   */
  media: MediumResource[];
}
/**
 * @generated from protobuf message auto_reserve.affiliate.medium.GetRequest
 */
export interface GetRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.affiliate.medium.DataRequest
 */
export interface DataRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string start_date = 2;
   */
  startDate: string;
  /**
   * @generated from protobuf field: string end_date = 3;
   */
  endDate: string;
}
/**
 * @generated from protobuf message auto_reserve.affiliate.medium.DataResource
 */
export interface DataResource {
  /**
   * @generated from protobuf field: string date = 1;
   */
  date: string;
  /**
   * @generated from protobuf field: int64 pv = 2;
   */
  pv: number;
  /**
   * @generated from protobuf field: int64 uu = 3;
   */
  uu: number;
  /**
   * @generated from protobuf field: int64 reservations_count = 4;
   */
  reservationsCount: number;
}
/**
 * @generated from protobuf message auto_reserve.affiliate.medium.DataResponse
 */
export interface DataResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.affiliate.medium.DataResource data = 1;
   */
  data: DataResource[];
}
/**
 * @generated from protobuf message auto_reserve.affiliate.medium.TransfersRequest
 */
export interface TransfersRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.affiliate.medium.TransferResource
 */
export interface TransferResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: int64 amount = 2;
   */
  amount: number;
  /**
   * @generated from protobuf field: string reward_month = 3;
   */
  rewardMonth: string;
  /**
   * @generated from protobuf field: bool is_transfered = 4;
   */
  isTransfered: boolean;
}
/**
 * @generated from protobuf message auto_reserve.affiliate.medium.TransfersResponse
 */
export interface TransfersResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.affiliate.medium.TransferResource transfers = 1;
   */
  transfers: TransferResource[];
}
/**
 * @generated from protobuf message auto_reserve.affiliate.medium.AffiliatesRequest
 */
export interface AffiliatesRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string start_date = 2;
   */
  startDate: string;
  /**
   * @generated from protobuf field: string end_date = 3;
   */
  endDate: string;
  /**
   * @generated from protobuf field: repeated int64 statuses = 4;
   */
  statuses: number[];
  /**
   * @generated from protobuf field: int64 page = 5;
   */
  page: number;
  /**
   * @generated from protobuf field: int64 per = 6;
   */
  per: number;
  /**
   * @generated from protobuf field: string sort_column = 7;
   */
  sortColumn: string;
  /**
   * @generated from protobuf field: bool is_download = 8;
   */
  isDownload: boolean;
}
/**
 * @generated from protobuf message auto_reserve.affiliate.medium.AffiliateResource
 */
export interface AffiliateResource {
  /**
   * @generated from protobuf field: string affiliated_at = 1;
   */
  affiliatedAt: string; // 発生日
  /**
   * @generated from protobuf field: string approved_at = 2;
   */
  approvedAt: string; // 確定日
  /**
   * @generated from protobuf field: auto_reserve.affiliate.medium.AffiliateResource.Status status = 3;
   */
  status: AffiliateResource_Status;
  /**
   * @generated from protobuf field: string status_str = 4;
   */
  statusStr: string;
  /**
   * @generated from protobuf field: int64 amount = 5;
   */
  amount: number; // 報酬
  /**
   * @generated from protobuf field: int64 index = 6;
   */
  index: number;
}
/**
 * @generated from protobuf enum auto_reserve.affiliate.medium.AffiliateResource.Status
 */
export enum AffiliateResource_Status {
  /**
   * 未承認
   *
   * @generated from protobuf enum value: UNAPPROVED = 0;
   */
  UNAPPROVED = 0,
  /**
   * 承認
   *
   * @generated from protobuf enum value: APPROVED = 1;
   */
  APPROVED = 1,
  /**
   * 否認
   *
   * @generated from protobuf enum value: DENIAL = 2;
   */
  DENIAL = 2,
}
/**
 * @generated from protobuf message auto_reserve.affiliate.medium.AffiliatesResponse
 */
export interface AffiliatesResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.affiliate.medium.AffiliateResource affiliates = 1;
   */
  affiliates: AffiliateResource[];
  /**
   * @generated from protobuf field: int64 total_count = 2;
   */
  totalCount: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class ListRequest$Type extends MessageType<ListRequest> {
  constructor() {
    super("auto_reserve.affiliate.medium.ListRequest", []);
  }
  create(value?: PartialMessage<ListRequest>): ListRequest {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListRequest,
  ): ListRequest {
    return target ?? this.create();
  }
  internalBinaryWrite(
    message: ListRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.medium.ListRequest
 */
export const ListRequest = new ListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResponse$Type extends MessageType<ListResponse> {
  constructor() {
    super("auto_reserve.affiliate.medium.ListResponse", [
      {
        no: 1,
        name: "media",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => MediumResource,
      },
    ]);
  }
  create(value?: PartialMessage<ListResponse>): ListResponse {
    const message = { media: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListResponse,
  ): ListResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.affiliate.medium.MediumResource media */ 1:
          message.media.push(
            MediumResource.internalBinaryRead(reader, reader.uint32(), options),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.affiliate.medium.MediumResource media = 1; */
    for (let i = 0; i < message.media.length; i++)
      MediumResource.internalBinaryWrite(
        message.media[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.medium.ListResponse
 */
export const ListResponse = new ListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRequest$Type extends MessageType<GetRequest> {
  constructor() {
    super("auto_reserve.affiliate.medium.GetRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<GetRequest>): GetRequest {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetRequest,
  ): GetRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.medium.GetRequest
 */
export const GetRequest = new GetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataRequest$Type extends MessageType<DataRequest> {
  constructor() {
    super("auto_reserve.affiliate.medium.DataRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "end_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<DataRequest>): DataRequest {
    const message = { id: "", startDate: "", endDate: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<DataRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DataRequest,
  ): DataRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string start_date */ 2:
          message.startDate = reader.string();
          break;
        case /* string end_date */ 3:
          message.endDate = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: DataRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string start_date = 2; */
    if (message.startDate !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.startDate);
    /* string end_date = 3; */
    if (message.endDate !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.endDate);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.medium.DataRequest
 */
export const DataRequest = new DataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataResource$Type extends MessageType<DataResource> {
  constructor() {
    super("auto_reserve.affiliate.medium.DataResource", [
      { no: 1, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: "pv",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 3,
        name: "uu",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 4,
        name: "reservations_count",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<DataResource>): DataResource {
    const message = { date: "", pv: 0, uu: 0, reservationsCount: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<DataResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DataResource,
  ): DataResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string date */ 1:
          message.date = reader.string();
          break;
        case /* int64 pv */ 2:
          message.pv = reader.int64().toNumber();
          break;
        case /* int64 uu */ 3:
          message.uu = reader.int64().toNumber();
          break;
        case /* int64 reservations_count */ 4:
          message.reservationsCount = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: DataResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string date = 1; */
    if (message.date !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.date);
    /* int64 pv = 2; */
    if (message.pv !== 0) writer.tag(2, WireType.Varint).int64(message.pv);
    /* int64 uu = 3; */
    if (message.uu !== 0) writer.tag(3, WireType.Varint).int64(message.uu);
    /* int64 reservations_count = 4; */
    if (message.reservationsCount !== 0)
      writer.tag(4, WireType.Varint).int64(message.reservationsCount);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.medium.DataResource
 */
export const DataResource = new DataResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataResponse$Type extends MessageType<DataResponse> {
  constructor() {
    super("auto_reserve.affiliate.medium.DataResponse", [
      {
        no: 1,
        name: "data",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => DataResource,
      },
    ]);
  }
  create(value?: PartialMessage<DataResponse>): DataResponse {
    const message = { data: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<DataResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DataResponse,
  ): DataResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.affiliate.medium.DataResource data */ 1:
          message.data.push(
            DataResource.internalBinaryRead(reader, reader.uint32(), options),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: DataResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.affiliate.medium.DataResource data = 1; */
    for (let i = 0; i < message.data.length; i++)
      DataResource.internalBinaryWrite(
        message.data[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.medium.DataResponse
 */
export const DataResponse = new DataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransfersRequest$Type extends MessageType<TransfersRequest> {
  constructor() {
    super("auto_reserve.affiliate.medium.TransfersRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<TransfersRequest>): TransfersRequest {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<TransfersRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: TransfersRequest,
  ): TransfersRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: TransfersRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.medium.TransfersRequest
 */
export const TransfersRequest = new TransfersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransferResource$Type extends MessageType<TransferResource> {
  constructor() {
    super("auto_reserve.affiliate.medium.TransferResource", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: "amount",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 3,
        name: "reward_month",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: "is_transfered",
        kind: "scalar",
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
  create(value?: PartialMessage<TransferResource>): TransferResource {
    const message = { id: "", amount: 0, rewardMonth: "", isTransfered: false };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<TransferResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: TransferResource,
  ): TransferResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* int64 amount */ 2:
          message.amount = reader.int64().toNumber();
          break;
        case /* string reward_month */ 3:
          message.rewardMonth = reader.string();
          break;
        case /* bool is_transfered */ 4:
          message.isTransfered = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: TransferResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* int64 amount = 2; */
    if (message.amount !== 0)
      writer.tag(2, WireType.Varint).int64(message.amount);
    /* string reward_month = 3; */
    if (message.rewardMonth !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.rewardMonth);
    /* bool is_transfered = 4; */
    if (message.isTransfered !== false)
      writer.tag(4, WireType.Varint).bool(message.isTransfered);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.medium.TransferResource
 */
export const TransferResource = new TransferResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransfersResponse$Type extends MessageType<TransfersResponse> {
  constructor() {
    super("auto_reserve.affiliate.medium.TransfersResponse", [
      {
        no: 1,
        name: "transfers",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TransferResource,
      },
    ]);
  }
  create(value?: PartialMessage<TransfersResponse>): TransfersResponse {
    const message = { transfers: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<TransfersResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: TransfersResponse,
  ): TransfersResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.affiliate.medium.TransferResource transfers */ 1:
          message.transfers.push(
            TransferResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: TransfersResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.affiliate.medium.TransferResource transfers = 1; */
    for (let i = 0; i < message.transfers.length; i++)
      TransferResource.internalBinaryWrite(
        message.transfers[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.medium.TransfersResponse
 */
export const TransfersResponse = new TransfersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AffiliatesRequest$Type extends MessageType<AffiliatesRequest> {
  constructor() {
    super("auto_reserve.affiliate.medium.AffiliatesRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "end_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: "statuses",
        kind: "scalar",
        repeat: 1 /*RepeatType.PACKED*/,
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 5,
        name: "page",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 6,
        name: "per",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 7,
        name: "sort_column",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 8, name: "is_download", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
  create(value?: PartialMessage<AffiliatesRequest>): AffiliatesRequest {
    const message = {
      id: "",
      startDate: "",
      endDate: "",
      statuses: [],
      page: 0,
      per: 0,
      sortColumn: "",
      isDownload: false,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<AffiliatesRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: AffiliatesRequest,
  ): AffiliatesRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string start_date */ 2:
          message.startDate = reader.string();
          break;
        case /* string end_date */ 3:
          message.endDate = reader.string();
          break;
        case /* repeated int64 statuses */ 4:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; )
              message.statuses.push(reader.int64().toNumber());
          else message.statuses.push(reader.int64().toNumber());
          break;
        case /* int64 page */ 5:
          message.page = reader.int64().toNumber();
          break;
        case /* int64 per */ 6:
          message.per = reader.int64().toNumber();
          break;
        case /* string sort_column */ 7:
          message.sortColumn = reader.string();
          break;
        case /* bool is_download */ 8:
          message.isDownload = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: AffiliatesRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string start_date = 2; */
    if (message.startDate !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.startDate);
    /* string end_date = 3; */
    if (message.endDate !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.endDate);
    /* repeated int64 statuses = 4; */
    if (message.statuses.length) {
      writer.tag(4, WireType.LengthDelimited).fork();
      for (let i = 0; i < message.statuses.length; i++)
        writer.int64(message.statuses[i]);
      writer.join();
    }
    /* int64 page = 5; */
    if (message.page !== 0) writer.tag(5, WireType.Varint).int64(message.page);
    /* int64 per = 6; */
    if (message.per !== 0) writer.tag(6, WireType.Varint).int64(message.per);
    /* string sort_column = 7; */
    if (message.sortColumn !== "")
      writer.tag(7, WireType.LengthDelimited).string(message.sortColumn);
    /* bool is_download = 8; */
    if (message.isDownload !== false)
      writer.tag(8, WireType.Varint).bool(message.isDownload);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.medium.AffiliatesRequest
 */
export const AffiliatesRequest = new AffiliatesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AffiliateResource$Type extends MessageType<AffiliateResource> {
  constructor() {
    super("auto_reserve.affiliate.medium.AffiliateResource", [
      {
        no: 1,
        name: "affiliated_at",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: "approved_at",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: "status",
        kind: "enum",
        T: () => [
          "auto_reserve.affiliate.medium.AffiliateResource.Status",
          AffiliateResource_Status,
        ],
      },
      { no: 4, name: "status_str", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: "amount",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 6,
        name: "index",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<AffiliateResource>): AffiliateResource {
    const message = {
      affiliatedAt: "",
      approvedAt: "",
      status: 0,
      statusStr: "",
      amount: 0,
      index: 0,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<AffiliateResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: AffiliateResource,
  ): AffiliateResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string affiliated_at */ 1:
          message.affiliatedAt = reader.string();
          break;
        case /* string approved_at */ 2:
          message.approvedAt = reader.string();
          break;
        case /* auto_reserve.affiliate.medium.AffiliateResource.Status status */ 3:
          message.status = reader.int32();
          break;
        case /* string status_str */ 4:
          message.statusStr = reader.string();
          break;
        case /* int64 amount */ 5:
          message.amount = reader.int64().toNumber();
          break;
        case /* int64 index */ 6:
          message.index = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: AffiliateResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string affiliated_at = 1; */
    if (message.affiliatedAt !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.affiliatedAt);
    /* string approved_at = 2; */
    if (message.approvedAt !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.approvedAt);
    /* auto_reserve.affiliate.medium.AffiliateResource.Status status = 3; */
    if (message.status !== 0)
      writer.tag(3, WireType.Varint).int32(message.status);
    /* string status_str = 4; */
    if (message.statusStr !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.statusStr);
    /* int64 amount = 5; */
    if (message.amount !== 0)
      writer.tag(5, WireType.Varint).int64(message.amount);
    /* int64 index = 6; */
    if (message.index !== 0)
      writer.tag(6, WireType.Varint).int64(message.index);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.medium.AffiliateResource
 */
export const AffiliateResource = new AffiliateResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AffiliatesResponse$Type extends MessageType<AffiliatesResponse> {
  constructor() {
    super("auto_reserve.affiliate.medium.AffiliatesResponse", [
      {
        no: 1,
        name: "affiliates",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AffiliateResource,
      },
      {
        no: 2,
        name: "total_count",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<AffiliatesResponse>): AffiliatesResponse {
    const message = { affiliates: [], totalCount: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<AffiliatesResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: AffiliatesResponse,
  ): AffiliatesResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.affiliate.medium.AffiliateResource affiliates */ 1:
          message.affiliates.push(
            AffiliateResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* int64 total_count */ 2:
          message.totalCount = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: AffiliatesResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.affiliate.medium.AffiliateResource affiliates = 1; */
    for (let i = 0; i < message.affiliates.length; i++)
      AffiliateResource.internalBinaryWrite(
        message.affiliates[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int64 total_count = 2; */
    if (message.totalCount !== 0)
      writer.tag(2, WireType.Varint).int64(message.totalCount);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.medium.AffiliatesResponse
 */
export const AffiliatesResponse = new AffiliatesResponse$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.affiliate.medium.Medium
 */
export const Medium = new ServiceType("auto_reserve.affiliate.medium.Medium", [
  { name: "List", options: {}, I: ListRequest, O: ListResponse },
  { name: "Get", options: {}, I: GetRequest, O: MediumResource },
  { name: "Data", options: {}, I: DataRequest, O: DataResponse },
  { name: "Transfers", options: {}, I: TransfersRequest, O: TransfersResponse },
  {
    name: "Affiliates",
    options: {},
    I: AffiliatesRequest,
    O: AffiliatesResponse,
  },
]);
