import { AuthClient } from '@hello-ai/proto/src/gen/auto_reserve/affiliate/auth/auth_service.client'
import { isAxiosError } from 'modules/axios'
import useSWR, { mutate } from 'modules/swr'

import { getErrorMessage } from 'modules/getErrorMessage'
import { createRpcService } from 'modules/rpc'

import { message } from 'antd'

export const authService = createRpcService(AuthClient)

export type Token = string
export interface Auth {
  id: string
  token: Token
}

export const AUTH_KEY = 'ForAffiliateWeb:auth' as const

export function onError(error: unknown) {
  if (isAxiosError(error) && error.response?.status === 401) {
    signOut()
  }
  message.error(getErrorMessage(error))
}

export async function signOut() {
  localStorage.removeItem(AUTH_KEY)
  mutate(AUTH_KEY, null, false)
}

export async function signIn(email: string, password: string) {
  const { response, error } = await authService.signIn(null, {
    email,
    password,
  })

  const auth =
    response === undefined
      ? undefined
      : {
          id: response.id,
          token: response.token,
        }

  if (response != null) {
    localStorage.setItem(AUTH_KEY, JSON.stringify(auth) ?? 'null')
    mutate(AUTH_KEY, auth)
  }

  if (error != null) {
    onError(error)
  }

  return {
    auth,
    error,
  }
}

const authData = JSON.parse(localStorage.getItem(AUTH_KEY) ?? 'null')

export function useAuth() {
  const { data: auth, mutate } = useSWR<Auth | null>(AUTH_KEY, null, {
    fallbackData: authData,
  })

  return {
    auth: auth ?? undefined,
    mutate,
  }
}

export function useToken() {
  const { auth } = useAuth()
  return auth?.token ?? null
}
